import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country } from '@shared/types/country.type';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NavBarUpdates } from '@shared/types/navbar-updates.type';
import { IndustrySector } from '@shared/types/industry-sector.type';
import { Response } from '@shared/types/generic-response.type';
import { DropdownOption } from '@shared/components/dropdown/types/dropdown-option.type';
import { Currency } from '@shared/types/currency.type';

type CountriesResponse = {
  countries: Country[];
};

type DetectedLocation = {country: string, country_code: string};

type IndustrySectorsResponse = {
  industry_sectors: IndustrySector[];
};

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _industrySectors!: IndustrySector[];
  private _countries!: Country[];
  private _currencies!: Currency[];
  public detectedCountry?: Country;
  private _detectedLocation!: DetectedLocation;

  set industrySectors(industrySectors: IndustrySector[]) {
    this._industrySectors = industrySectors;
  }

  set countries(countries: Country[]) {
    this._countries = countries;
  }

  set currencies(currencies: Currency[]) {
    this._currencies = currencies;
  }

  get industrySectors(): IndustrySector[] {
    return this._industrySectors;
  }

  get countries(): Country[] {
    return this._countries;
  }

  get currencies(): Currency[] {
    return this._currencies;
  }

  navbarChanges$: Subject<Partial<NavBarUpdates>> = new Subject();
  public dropdownChanges$: Subject<DropdownOption[]> = new Subject<DropdownOption[]>();

  constructor(private _Http: HttpClient) { }

  /**
  * @description set the detected country from the countries array
  * @param iso2 string
  * @returns Country | undefined
  */
  public setTheDetectedLocation(iso2?: string): Country | undefined{
    return this.countries?.find(country => country.iso2 === iso2 || country.iso2 === this._detectedLocation?.country_code)
  }

  /**
   * Get the detected user location from IP address
   * @returns Response<DetectedLocation>
   */
  public getDetectedLocation(): Observable<Response<DetectedLocation>> {
    return this._Http.get<Response<DetectedLocation>>(
      `${environment.genericApi}ip-to-country`
    ).pipe(tap((response) => {
      this._detectedLocation = response.data
      this.detectedCountry = this.setTheDetectedLocation();
    }));
  }

  /**
   * Get All countries Objects
   * @returns Response<{ countries: Country[] }>
   */
  public getAllCountries(): Observable<Response<CountriesResponse>> {
    return this._Http.get<Response<CountriesResponse>>(
      `${environment.genericApi}countries`
    ).pipe(tap((response) => {
      this.countries = response.data.countries;
      this.getDetectedLocation().subscribe();
    }));
  }

  /**
   * @description Get all currencies
   * @returns Observable<Response<{ currencies: Currency[] }>>
   */
  public getAllCurrencies(): Observable<Response<{ currencies: Currency[] }>>{
    return this._Http.get<Response<{ currencies: Currency[] }>>(
      `${environment.genericApi}currencies`
    )
  }

  /**
   * @method setIndustrySectors
   * @description Method to set industry sectors in the subject
   * @returns Response<IndustrySector[]>
   */
  public getIndustrySectorList(): Observable<
    Response<IndustrySectorsResponse>
  > {
    return this._Http.get<Response<IndustrySectorsResponse>>(
      `${environment.genericApi}industry-sectors`
    );
  }

  public updateNavbar(changes: Partial<NavBarUpdates>) {
    this.navbarChanges$.next(changes);
  }

  /**
   * objectToFormData
   * @description Creates a formData from an object
   * @param obj - the object to create the formData from
   * @returns formData
   * */
  objectToFormData(obj: { [key: string]: any }): FormData {
    const formData = new FormData();
    this._appendFormData(formData, obj, '');
    return formData;
  }

  /**
   * _appendFormData
   * @description Appends data from object into a formData
   * @returns void
   * */
  private _appendFormData(
    formData: FormData,
    obj: { [key: string]: any },
    keyPrefix: string = ''
  ): void {
    for (const key in obj) {
      const newKey = keyPrefix ? `${keyPrefix}[${key}]` : key;
      const value = obj[key];
      if (Array.isArray(value)) {
        value.forEach((item: any, index: number) => {
          if (item instanceof File || typeof item === 'string') {
            formData.append(`${newKey}[${index}]`, item);
            return;
          }
          if (typeof item !== 'string')
            this._appendFormData(formData, item, `${newKey}[${index}]`);
        });
      } else if (
        typeof value === 'object' &&
        value !== null &&
        !(value instanceof File)
      ) {
        this._appendFormData(formData, value, newKey);
      } else {
        formData.append(newKey, value);
      }
    }
  }

  /**
   * @method checkForEmailDomain
   * @description Method to check if email domain is valid or not
   * @param Observable<Response<{ valid_domain: boolean }>>
   */
  public checkForEmailDomain(
    email: string
  ): Observable<Response<{ valid_domain: boolean }>> {
    return this._Http.post<Response<{ valid_domain: boolean }>>(
      `${environment.genericApi}verify-domain/`,
      {
        email,
      }
    );
  }

  /**
   * @method checkForEventShortName
   * @description Method to check if event short name exists
   * @param organization number
   * @param shortName string
   * @param eventId number
   * @returns Observable<Response<{ short_name_exists: boolean }>>
   * */
  public checkForEventShortName(
    organization: number,
    shortName: string,
    eventId?: number
  ): Observable<Response<{ short_name_exists: boolean }>> {
    return this._Http.get<Response<{ short_name_exists: boolean }>>(
      `${environment.planApi
      }${organization}/events/short-name-exists?short_name=${shortName}${eventId ? `&event_id=${eventId}` : ''
      }`
    );
  }

  /**
   * @method setCountryFlags
   * @description Method to set country flags
   * @returns void
   */
  setCountryFlags() {
    this.countries.forEach((country) => {
      const flagPath = `assets/flags/${country.iso2.toLowerCase()}.png`;
      country.flag = flagPath;
    });
  }
  /**
   * @description Method to get the country code from the search term either if it's the iso2 or the name
   * @param searchTerm string
   * @returns string | null
   */
  public getCountryCode(searchTerm: string): string {
    const lowerCasedTerm = searchTerm?.toLowerCase();

    for (const country of this.countries) {
      // Check for a match with iso2
      if (country.iso2.toLowerCase() === lowerCasedTerm) {
        return country.country_code;
      }

      // Check for a match with name
      if (country.name.toLowerCase() === lowerCasedTerm) {
        return country.country_code;
      }

      // Pre-process the alternatives only once per country
      const alternatives = country.alternatives
        .split(',')
        .map((alt) => alt.toLowerCase().trim());

      // Check for a match with alternatives
      if (alternatives.includes(lowerCasedTerm)) {
        return country.country_code;
      }
    }

    return '';
  }


  /**
   * @description Method to load the google maps script
   */
  loadGoogleMaps(): void {
    if (document.getElementById('google-maps-script')) return;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.GOOGLE_MAPS_API_KEY}`;
    script.defer = true;
    script.async = true;
    script.id = 'google-maps-script';
    document.head.appendChild(script);
  }

  /**
  * @description Global function to download a blob
  * @param blob Blob | null
  * @param fileName string | null
  */
  public downloadBlob(blob: Blob | null, fileName: string | null): void {
    if(!blob || !fileName) return;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
